import { Inject, Injectable, signal } from '@angular/core';
import { Feature } from 'src/app/models/feature';
import { environment } from 'src/environments/environment';
import { firstValueFrom, Subscription } from 'rxjs';
import { ConsoleContent } from 'src/app/models/console-content';
import { PatientFormsService } from '../patient-forms/patient-forms.service';
import { ErrorHandlerService } from '../_core/error-handler/error-handler.service';
import { PatientForm } from 'src/app/models/patient-form';
import { AuthService } from '../_core/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  env = environment;

  reloadSignal = signal<Boolean>(false);
  featureSignal = signal<Feature[]>([]);

  features: Feature[] = [
    {
      icon: 'messages.png',
      name: 'View Messages',
      description: 'View messages',
      tagline: '',
      category: 'HOME',
      tags: [],
      path: '/tabs/messages',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 1,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'messages-send.png',
      name: 'Send a message',
      description: 'Send a message to your care team',
      tagline: 'Send a message to your care team',
      category: 'HOME',
      tags: ['send a new message'],
      path: '/tabs/messages/inbox/new-message',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 2,
      requiresAccount: true,
      featureOnSignInPage: true,
      signInPageOrder: 1
    },
    {
      icon: 'calendar.png',
      name: 'Request an appointment',
      description: 'Request an appointment',
      tagline: 'Request appointments',
      category: 'HOME',
      tags: [],
      path: '/tabs/messages/inbox/new-message/request-appointment',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 3,
      requiresAccount: true,
      featureOnSignInPage: true,
      signInPageOrder: 2
    },
    {
      icon: 'medicines.png',
      name: 'Request a prescription refill',
      description: 'Request a prescription refill',
      tagline: 'Request prescription refills',
      category: 'HOME',
      tags: [],
      path: '/tabs/messages/inbox/new-message/prescription-refill',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 4,
      requiresAccount: true,
      featureOnSignInPage: true,
      signInPageOrder: 4
    },
    {
      icon: 'pay-a-bill.png',
      name: 'Pay a medical bill',
      description: 'Pay a medical bill',
      tagline: '',
      category: 'HOME',
      tags: [],
      path: this.env.urls.payABill,
      isActive: true,
      presentation: 'external',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: true,
      isFeaturedTile: true,
      tileOrder: 5,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'health-record.png',
      name: 'Health Record',
      description: 'View health record',
      tagline: 'View your health record',
      category: 'HOME',
      tags: [],
      path: '/tabs/health-record',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 6,
      requiresAccount: true,
      featureOnSignInPage: true,
      signInPageOrder: 3
    },
    {
      icon: 'test-results.png',
      name: 'Test Results',
      description: 'View/share test results',
      tagline: 'View test results',
      category: 'HOME',
      tags: [],
      path: '/tabs/health-record/test-results',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: true,
      isFeaturedTile: true,
      tileOrder: 7,
      requiresAccount: true,
      featureOnSignInPage: true,
      signInPageOrder: 5
    },
    {
      icon: 'share-record.png',
      name: 'Share Health Record',
      description: 'Share health record',
      tagline: 'Share health record',
      category: 'HOME',
      tags: [],
      path: '/tabs/health-record/share',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: true,
      isFeaturedTile: true,
      tileOrder: 8,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: 5
    },
    {
      icon: 'vaccinations.png',
      name: 'Vaccinations',
      description: 'View vaccinations',
      tagline: 'View vaccinations',
      category: 'HOME',
      tags: [],
      path: '/tabs/health-record/vaccinations',
      isActive: false,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: -1,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'request-proxy-access.png',
      name: 'Request Proxy Access',
      description: 'Request proxy access',
      tagline: 'Request proxy access',
      category: 'HOME',
      tags: [],
      path: this.env.urls.addProxyForm,
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 10,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'patient-forms.png',
      name: 'Patient Forms',
      description: 'Complete forms',
      tagline: 'Complete forms',
      category: 'HOME',
      tags: [],
      path: '/tabs/resources/patient-forms',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: true,
      isFeaturedTile: false,
      tileOrder: -1,
      requiresAccount: true,
      featureOnSignInPage: true,
      signInPageOrder: 6
    },
    {
      icon: 'tasks.png',
      name: 'Things to Do',
      description: 'Complete things to do',
      tagline: 'Complete things to do',
      category: 'HOME',
      tags: [],
      path: '/account/tasks',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: false,
      tileOrder: -1,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'test-results.png',
      name: 'Test Results',
      description: 'View test results',
      tagline: 'View test results',
      category: 'HEALTH RECORD',
      tags: [
        'lab results', 'imaging results', 'diagnostic imaging', 'labs', 'rads',
        'lab tests', 'ct', 'mri', 'ultrasound', 'laboratory', 'brl', '3300', 'xray', 'x-ray',
        'bone'
      ],
      path: '/tabs/health-record/test-results',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 1,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'all-visits.png',
      name: 'Appointments and Visits',
      description: 'View upcoming appointments and past visits.',
      tagline: '',
      category: 'HEALTH RECORD',
      tags: [],
      path: '/tabs/health-record/visits',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 2,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'reports.png',
      name: 'Documents and Reports',
      description: 'View reports, notes, and documentation.',
      tagline: '',
      category: 'HEALTH RECORD',
      tags: [],
      path: '/tabs/health-record/reports',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 3,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'medicines.png',
      name: 'Medicines',
      description: 'View active and inactive medicines.',
      tagline: '',
      category: 'HEALTH RECORD',
      tags: [],
      path: '/tabs/health-record/medicines',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 4,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'health-stats.png',
      name: 'Health Stats',
      description: 'View captured health stats.',
      tagline: '',
      category: 'HEALTH RECORD',
      tags: [],
      path: '/tabs/health-record/health-stats',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 5,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'conditions.png',
      name: 'Health Conditions',
      description: 'View health conditions.',
      tagline: '',
      category: 'HEALTH RECORD',
      tags: [],
      path: '/tabs/health-record/health-conditions',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 6,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'allergies.png',
      name: 'Allergies',
      description: 'View reported allergies.',
      tagline: '',
      category: 'HEALTH RECORD',
      tags: [],
      path: '/tabs/health-record/allergies',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 7,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'vaccinations.png',
      name: 'Vaccinations',
      description: 'View vaccinations.',
      tagline: '',
      category: 'HEALTH RECORD',
      tags: [],
      path: '/tabs/health-record/vaccinations',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 8,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'share-record.png',
      name: 'Share health record',
      description: 'Share health record.',
      tagline: '',
      category: 'HEALTH RECORD',
      tags: [],
      path: '/tabs/health-record/share',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 9,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'messages-new.png',
      name: 'Inbox Messages',
      description: 'View messages you sent.',
      tagline: '',
      category: 'MESSAGES',
      tags: [],
      path: '/tabs/messages/inbox',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: -1,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'messages-sent.png',
      name: 'Sent Messages',
      description: 'View messages you sent.',
      tagline: '',
      category: 'MESSAGES',
      tags: [],
      path: '/tabs/messages/sent',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: -1,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'messages-opened.png',
      name: 'Archived Messages',
      description: 'View archived messages.',
      tagline: '',
      category: 'MESSAGES',
      tags: [],
      path: '/tabs/messages/archived',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: -1,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    // RESOURCES
    // {
    //   icon: 'ask-a-question.png',
    //   name: 'Ask a Librarian',
    //   description: 'Ask a librarian.',
    //   tagline: '',
    //   category: 'RESOURCES',
    //   tags: [],
    //   path: this.env.urls.askALibrarian,
    //   isActive: true,
    //   presentation: 'iframe',
    //   roles: ['USER', 'PROXY', 'GUARD-PROXY'],
    //   hideFromSearch: false,
    //   isFeaturedTile: true,
    //   tileOrder: 1,
    //   requiresAccount: true,
    //   featureOnSignInPage: false,
    //   signInPageOrder: -1
    // },
    {
      icon: 'patient-forms.png',
      name: 'Patient Forms',
      description: 'Complete a form.',
      tagline: '',
      category: 'RESOURCES',
      tags: [],
      path: '/tabs/resources/patient-forms',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 2,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    // {
    //   icon: 'mobile-apps.png',
    //   name: 'Explore our Apps',
    //   description: 'Explore our apps.',
    //   tagline: '',
    //   category: 'RESOURCES',
    //   tags: [],
    //   path: '/tabs/resources/apps',
    //   isActive: true,
    //   presentation: 'integrated',
    //   roles: ['USER', 'PROXY', 'GUARD-PROXY'],
    //   hideFromSearch: false,
    //   isFeaturedTile: true,
    //   tileOrder: 3,
    //   requiresAccount: true,
    //   featureOnSignInPage: false,
    //   signInPageOrder: -1
    // },
    {
      icon: 'apple-health.png',
      name: 'Connect Apple Health to your Record',
      description: '',
      tagline: '',
      category: 'RESOURCES',
      tags: ['apple health', 'ios', 'mobile apps'],
      path: '/tabs/resources/connect-mobile-apps',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 3,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'dictionary.png',
      name: 'Health Dictionary',
      description: 'Browse our health dictionary.',
      tagline: '',
      category: 'RESOURCES',
      tags: [],
      path: '/tabs/resources/health-dictionary',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 4,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'providers.png',
      name: 'Find a Provider',
      description: 'Find a provider.',
      tagline: '',
      category: 'RESOURCES',
      tags: [],
      path: this.env.urls.findAProvider,
      isActive: true,
      presentation: 'external',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 5,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'pay-a-bill.png',
      name: 'Pay a Medical Bill',
      description: 'Pay a medical bill.',
      tagline: '',
      category: 'RESOURCES',
      tags: [],
      path: this.env.urls.payABill,
      isActive: true,
      presentation: 'external',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 6,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'request-records.png',
      name: 'Records Request',
      description: 'Request a copy of your records.',
      tagline: '',
      category: 'RESOURCES',
      tags: [],
      path: this.env.urls.recordRequest,
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 6,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'lab-xray-work.png',
      name: 'Get in line for lab or radiology work',
      description: 'Get in line for lab or radiology work',
      tagline: '',
      category: 'RESOURCES',
      tags: [],
      path: '/tabs/resources/get-in-line',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 6,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'wayfinding.png',
      name: 'Find my way (Wayfinding)',
      description: 'Find my way inside Baystate Health facilities',
      tagline: '',
      category: 'RESOURCES',
      tags: [],
      path: 'https://atobe.app/photo-route?buildingId=26&MultiBuilding=26%3B16%3B27%3B15&routeLang=en&channel=GATEWAY',
      isActive: true,
      presentation: 'external',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 6,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    // PUBLIC RESOURCES
    {
      icon: 'apple-health.png',
      name: 'Connect Apple Health to your Record',
      description: '',
      tagline: '',
      category: 'PUBLIC-RESOURCES',
      tags: ['apple health', 'ios', 'mobile apps'],
      path: '/tabs/resources/connect-mobile-apps',
      isActive: true,
      presentation: 'integrated',
      roles: [],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 3,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'dictionary.png',
      name: 'Health Dictionary',
      description: 'Browse our health dictionary.',
      tagline: '',
      category: 'PUBLIC-RESOURCES',
      tags: [],
      path: '/tabs/resources/health-dictionary',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 4,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'lab-xray-work.png',
      name: 'Get in line for lab or radiology work',
      description: 'Get in line for lab or radiology work',
      tagline: '',
      category: 'PUBLIC-RESOURCES',
      tags: [],
      path: '/tabs/resources/get-in-line',
      isActive: true,
      presentation: 'integrated',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 6,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'wayfinding.png',
      name: 'Find my way (Wayfinding)',
      description: 'Find my way inside Baystate Health facilities',
      tagline: '',
      category: 'PUBLIC-RESOURCES',
      tags: [],
      path: 'https://atobe.app/photo-route?buildingId=26&MultiBuilding=26%3B16%3B27%3B15&routeLang=en&channel=GATEWAY',
      isActive: true,
      presentation: 'external',
      roles: [],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: 6,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    // RECOMMENDED FEATURES
    {
      icon: 'patients-like-me.png',
      name: 'PatientsLikeMe',
      description: 'Join over 850,000 members harnessing the power of their health insights through shared experience.',
      tagline: '',
      category: 'RECOMMENDED',
      tags: [],
      path: this.env.urls.patientsLikeMe,
      isActive: true,
      presentation: 'external',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: -1,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
    {
      icon: 'ompractice.png',
      name: 'OM Practice',
      description: 'Over 100 live camera-on fitness and mindfulness classes a week to help you reduce stress, improve flexibility, get strong, and age well! Join the community and be at home in class!',
      tagline: '',
      category: 'RECOMMENDED',
      tags: [],
      path: this.env.urls.omPractice,
      isActive: true,
      presentation: 'external',
      roles: ['USER', 'PROXY', 'GUARD-PROXY'],
      hideFromSearch: false,
      isFeaturedTile: true,
      tileOrder: -1,
      requiresAccount: true,
      featureOnSignInPage: false,
      signInPageOrder: -1
    },
  ];

  subs: Subscription[] = [];

  constructor(
    @Inject(PatientFormsService) private formsService: PatientFormsService,
    @Inject(AuthService) private authService: AuthService,
    private errorHandler: ErrorHandlerService
  ) {
    // this.loadPatientForms();
    this.subscribeToUser();
  }

  subscribeToUser() {
    this.subs.push(
      this.authService.authUser.subscribe(u => {
        if (u && u.userId) {
          this.loadPatientForms();
        }
      })
    )
  }

  async loadLandingFeatures(landingFeatures: ConsoleContent[]) {
    for (const lf of landingFeatures) {
      if (this.features.find(f => f.name.toLowerCase() === lf.title.toLowerCase()) === undefined) {
        const feature: Feature = {
          icon: lf.image_url,
          name: lf.title,
          description: lf.body,
          path: lf.redirect_url,
          category: 'LANDING',
          tags: [],
          tagline: '',
          isActive: true,
          presentation: 'external',
          roles: ['USER', 'PROXY', 'GUARD-PROXY'],
          hideFromSearch: false,
          isFeaturedTile: false,
          tileOrder: -1,
          requiresAccount: false,
          featureOnSignInPage: false,
          signInPageOrder: -1
        };
        this.features.push(feature);
      }
    }
  }

  async loadSocialMediaFeatures(socialMedia: ConsoleContent[]) {
    for (const sm of socialMedia) {
      if (this.features.find(f => f.name.toLowerCase() === sm.title.toLowerCase()) === undefined) {
        const feature: Feature = {
          icon: sm.image_url,
          name: sm.title,
          description: sm.body,
          path: sm.redirect_url,
          category: 'SOCIAL',
          tags: [],
          tagline: '',
          isActive: true,
          presentation: 'external',
          roles: ['USER', 'PROXY', 'GUARD-PROXY'],
          hideFromSearch: false,
          isFeaturedTile: false,
          tileOrder: -1,
          requiresAccount: false,
          featureOnSignInPage: false,
          signInPageOrder: -1
        };
        this.features.push(feature);
      }
    }
  }

  async loadPatientForms() {
    try {
      const res = await firstValueFrom(this.formsService.getAllForms());
      const newForms: PatientForm[] = Object.assign([], res.Forms);

      for (const nf of newForms) {
        if (this.features.find(f => f.name.toLowerCase() === nf.Title.toLowerCase()) === undefined) {
          const feature: Feature = {
            icon: nf.Type === 'Printable' ? 'forms-pdf.png' : 'forms-electronic.png',
            name: nf.Title,
            description: 'Resources > Patient Forms > ' + nf.CategoryPath.join(' > '),
            path: this.setPatientFormPath(nf),
            category: 'PATIENT FORM',
            tags: [],
            tagline: '',
            isActive: true,
            presentation: nf.Type === 'Printable' ? 'external' : 'integrated',
            roles: ['USER', 'PROXY', 'GUARD-PROXY'],
            hideFromSearch: false,
            isFeaturedTile: false,
            tileOrder: -1,
            requiresAccount: false,
            featureOnSignInPage: false,
            signInPageOrder: -1
          };
          this.features.push(feature);
        }
      }
    } catch (err) {
      this.errorHandler.handleError(err, 'feature-service.loadPatientForms.');
    }
  }

  setPatientFormPath(pf: PatientForm) {
    if (pf.Type === 'Electronic') {
      return '/tabs/resources/patient-forms/new/' + pf.Identifier.toLowerCase();
    } else {
      return this.env.filesUrl + '/' + pf.Identifier;
    }
  }

  updateFeatureSignal(feature: Feature[]) {
      this.featureSignal.set(feature);
  }

  setReloadSignal(reload: Boolean) {
      this.reloadSignal.set(reload);
  }

}
