<div [formGroup]="formGroup">
  <div
    class="input-wrapper"
    [ngClass]="{
      'radio-list-wrapper': type === 'radio-list',
      'no-border': hideBorder
    }"
  >
    <div class="input-label" *ngIf="type !== 'checkbox' && type !== 'hidden' && !hideLabel && label">
      <span>{{ label | translate }}</span>
      <span class="required-marker" *ngIf="isRequired">*</span>
    </div>
    <!-- Text Input -->
    <input
      *ngIf="type === 'text' || type === 'phone'"
      [id]="id"
      [ngClass]="{
        invalid: !formGroup.controls[formControlName].valid,
        pristine: !formGroup.controls[formControlName].pristine,
        untouched: !formGroup.controls[formControlName].untouched
      }"
      [type]="type"
      [formControlName]="formControlName"
      fill="outline"
      placeholder="{{ placeholder | translate }}"
      [mask]="type === 'phone' ? '999-999-9999' : ''"
      [autocomplete]="autocomplete"
      [readonly]="readonly"
      [maxlength]="maxlength"
      [pattern]="pattern"
      [class]="width"
      (blur)="blurInput(formControlName)"
      (change)="valueChanged(index, label, $event)"
      (keydown)="handleKeyDown($event, 'txt')"
      (keyup)="handleKeyUp($event, 'txt')"
      (paste)="handlePaste($event, 'txt')"
    />

    <!-- Date Input -->
    <input
      *ngIf="type === 'date'"
      [id]="id"
      type="date"
      [ngClass]="{
        invalid: !formGroup.controls[formControlName].valid,
        pristine: !formGroup.controls[formControlName].pristine,
        untouched: !formGroup.controls[formControlName].untouched
      }"
      [formControlName]="formControlName"
      fill="outline"
      placeholder="{{ placeholder | translate }}"
      [autocomplete]="autocomplete"
      [readonly]="readonly"
      [maxlength]="maxlength"
      [pattern]="pattern"
      [class]="width"
      (blur)="blurInput(formControlName)"
      (change)="valueChanged(index, label, $event)"
      (keydown)="handleKeyDown($event, 'txt')"
      (keyup)="handleKeyUp($event, 'txt')"
      (paste)="handlePaste($event, 'txt')"
    />

    <!-- Password Input -->
    <input
      *ngIf="type === 'password'"
      [id]="id"
      [type]="revealPassword ? 'text' : 'password'"
      [formControlName]="formControlName"
      [ngClass]="{
        invalid: !formGroup.controls[formControlName].valid,
        pristine: !formGroup.controls[formControlName].pristine,
        untouched: !formGroup.controls[formControlName].untouched
      }"
      fill="outline"
      [placeholder]="placeholder ? placeholder : ''"
      [autocomplete]="autocomplete"
      [readonly]="readonly"
      [maxlength]="maxlength"
      [pattern]="pattern"
      [class]="width"
      (blur)="blurInput(formControlName)"
      (change)="valueChanged(index, label, $event)"
      (keydown)="handleKeyDown($event, 'txt')"
      (keyup)="handleKeyUp($event, 'txt')"
      (paste)="handlePaste($event, 'txt')"
    />
    <!-- Reveal password button -->
    <div
      class="reveal-password-button"
      (click)="revealPassword = !revealPassword"
      *ngIf="type === 'password'"
    >
      <ion-icon name="eye" *ngIf="!revealPassword"></ion-icon>
      <ion-icon name="eye-off-outline" *ngIf="revealPassword"></ion-icon>
    </div>

    <!-- Select -->
    <select
      *ngIf="type === 'select'"
      [id]="id"
      [formControlName]="formControlName"
      [ngClass]="{
        invalid: !formGroup.controls[formControlName].valid,
        pristine: !formGroup.controls[formControlName].pristine,
        untouched: !formGroup.controls[formControlName].untouched
      }"
      label-placement="stacked"
      fill="outline"
      [autocomplete]="autocomplete"
      [class]="width"
      (blur)="blurInput(formControlName)"
      (change)="valueChanged(index, label, $event)"
    >
      <option value=""></option>
      <option *ngFor="let opt of parsedSelectOptions" [value]="opt?.value">
        {{ opt?.label | translate }}
      </option>
    </select>
    <div class="select-chevron" *ngIf="type === 'select'">
      <ion-icon name="chevron-down-outline"></ion-icon>
    </div>
    <div
      class="select-placeholder"
      *ngIf="
        type === 'select' &&
        placeholder &&
        !formGroup.controls[formControlName].value
      "
    >
      {{ placeholder | translate }}
    </div>

    <!-- Textarea -->
    <textarea
      *ngIf="type === 'textarea'"
      [id]="id"
      fill="outline"
      [ngClass]="{
        invalid: !formGroup.controls[formControlName].valid,
        pristine: !formGroup.controls[formControlName].pristine,
        untouched: !formGroup.controls[formControlName].untouched
      }"
      placeholder="{{ placeholder | translate }}"
      [formControlName]="formControlName"
      [readonly]="readonly"
      [maxlength]="maxlength"
      [pattern]="pattern"
      [class]="width"
      (blur)="blurInput(formControlName)"
      (change)="valueChanged(index, label, $event)"
      (keydown)="handleKeyDown($event, 'txt')"
      (keyup)="handleKeyUp($event, 'txt')"
      (paste)="handlePaste($event, 'txt')"
    >
    </textarea>

    <!-- Radio List -->
    <ion-radio-group
      [formControlName]="formControlName"
      [ngClass]="{'disable-list': disableInput}"
      mode="md"
      *ngIf="type === 'radio-list'"
      [id]="id"
    >
      <ion-item *ngFor="let opt of parsedSelectOptions">
        <ion-radio label-placement="end" justify="start" [value]="opt?.value">
          <div>{{ opt?.label | translate }}</div>
          <ion-note *ngIf="opt?.detail">{{ opt?.detail | translate }}</ion-note>
        </ion-radio>
      </ion-item>
    </ion-radio-group>
  </div>

  <!-- Combo Box -->
  <div class="combo-box" *ngIf="type === 'combo-box'">
    <div class="combo-box-select-wrapper">
      <select
        [formControlName]="cbSelectControlName"
        [ngClass]="{
          invalid: !formGroup.controls[formControlName].valid,
          pristine: !formGroup.controls[formControlName].pristine,
          untouched: !formGroup.controls[formControlName].untouched
        }"
        fill="outline"
        [autocomplete]="autocomplete"
        (blur)="blurInput(formControlName)"
        (change)="valueChanged(index, label, $event)"
      >
        <option value="" selected>{{ "All" | translate }}</option>
        <option *ngFor="let opt of parsedSelectOptions" [value]="opt?.value">
          {{ opt?.label | translate }}
        </option>
      </select>
      <div class="select-chevron">
        <ion-icon name="chevron-down-outline"></ion-icon>
      </div>
    </div>

    <div class="combo-box-divider"></div>

    <input
      [id]="id"
      [ngClass]="{
        invalid: !formGroup.controls[formControlName].valid,
        pristine: !formGroup.controls[formControlName].pristine,
        untouched: !formGroup.controls[formControlName].untouched
      }"
      [formControlName]="formControlName"
      fill="outline"
      [placeholder]="placeholder ? placeholder : ''"
      [autocomplete]="autocomplete"
      [readonly]="readonly"
      (blur)="blurInput(formControlName)"
      (change)="valueChanged(index, label, $event)"
      (keydown)="handleKeyDown($event, 'txt')"
      (keyup)="handleKeyUp($event, 'txt')"
      (paste)="handlePaste($event, 'txt')"
    />
  </div>

  <!-- Checkbox -->
  <ion-checkbox
    *ngIf="type === 'checkbox'"
    [id]="id"
    [formControlName]="formControlName"
    labelPlacement="end"
    class="ion-text-wrap"
    mode="md"
    (ionChange)="valueChanged(index, label, $event.detail.checked)"
  >
    <div class="checkbox-label" *ngIf="!hideLabel">
      {{ label | translate }}
      <div class="helper-text" *ngIf="helperText">
        {{ helperText | translate }}
      </div>
    </div>
  </ion-checkbox>

  <!-- Hidden field -->
  <input
    *ngIf="type === 'hidden'"
    [id]="id"
    [ngClass]="{
      invalid: !formGroup.controls[formControlName].valid,
      pristine: !formGroup.controls[formControlName].pristine,
      untouched: !formGroup.controls[formControlName].untouched
    }"
    type="hidden"
    [formControlName]="formControlName"
    [class]="width"
    (change)="valueChanged(index, label, $event)"
    (paste)="handlePaste($event, 'txt')"
  />

  <!-- Helper Text -->
  <div class="helper-text" *ngIf="helperText && type !== 'checkbox'">
    {{ helperText | translate }}
  </div>

  <!-- Validation Messages -->
  <div class="validation-errors" *ngIf="validationMessages">
    <ng-container
      *ngFor="let validation of validationMessages[formControlName]"
    >
      <div
        class="ve-message"
        *ngIf="
          formGroup
            .get(formControlName)
            .hasError(validation.type.toLowerCase()) &&
          (formGroup.get(formControlName).dirty ||
            formGroup.get(formControlName).touched ||
            submitAttempted)
        "
      >
        <!-- <ion-icon name="alert-circle-outline"></ion-icon> -->
        {{ validation.message | translate }}
      </div>
    </ng-container>
  </div>
</div>
