import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonLabel, IonList, IonItem, IonListHeader, ModalController } from "@ionic/angular/standalone";
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Feature } from 'src/app/models/feature';
import { User } from 'src/app/models/user';
import { IframeModalPage } from 'src/app/pages/iframe-modal/iframe-modal.page';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { LoginRouteGuardService } from 'src/app/services/_core/login-route-guard/login-route-guard.service';
import { FeatureService } from 'src/app/services/feature/feature.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

@Component({
  selector: 'bh-site-index',
  templateUrl: './bh-site-index.component.html',
  styleUrls: ['./bh-site-index.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonList,
    IonList,
    IonItem,
    IonLabel,
    IonListHeader,
  ]
})
export class BhSiteIndexComponent  implements OnInit, OnChanges {
  @Output() clickEvent: EventEmitter<Feature> = new EventEmitter();
  updatedFeatures = this.featureSvc.featureSignal();
  features: Feature[] = [];
  healthRecordFeatures: Feature[] = [];
  messageFeatures: Feature[] = [];
  resourceFeatures: Feature[] = [];

  subs: Subscription[] = [];
  authUser: User;
  onDestroy$: Subject<boolean> = new Subject();

  constructor(
    private featureSvc: FeatureService,
    private navService: NavigationService,
    private authService: AuthService,
    private modalCtrl: ModalController,
    private loginService: LoginRouteGuardService
  ) { }


  ngOnChanges(): void {
    console.log('updatedFeatures', this.updatedFeatures);
    this.subscribeToUserState();
    this.parseFeatures();
  }

  ngOnInit() {
      this.parseFeaturesInit();
  }

  parseFeaturesInit() {
    setTimeout( () => {
      this.features = this.featureSvc.features;
      if(this.features.length > 0) {
        this.features = this.loginService.filterFeaturesByRole(this.features, this.authService.authUser.getValue());
        this.healthRecordFeatures = this.features.filter(f => f.category === 'HEALTH RECORD');
        this.messageFeatures = this.features.filter(f => f.category === 'MESSAGES');
        this.resourceFeatures = this.features.filter(f => f.category === 'RESOURCES');
      }
    }, 1000);
  }

  parseFeatures() {
    setTimeout( () => {
      if(this.updatedFeatures.length > 0) {
          this.updatedFeatures = this.loginService.filterFeaturesByRole(this.features, this.authUser);
          console.log('features.parseFeatures', this.features);
          this.healthRecordFeatures = this.updatedFeatures.filter(f => f.category === 'HEALTH RECORD');
          this.messageFeatures = this.updatedFeatures.filter(f => f.category === 'MESSAGES');
          this.resourceFeatures = this.updatedFeatures.filter(f => f.category === 'RESOURCES');
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  unsubscribe() {
    this.subs.forEach((s) => {
      s.unsubscribe();
      s = undefined;
    });
  }

    subscribeToUserState() {
      this.subs.push(
        this.authService.authUser
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((au) => {
            this.authUser = au;
          })
      );
    }

  openRoute(url) {
    this.navService.navigateForward(url);
    this.clickEvent.emit();
  }

  async openIframeModal(f: Feature) {
    const modal = await this.modalCtrl.create({
      component: IframeModalPage,
      componentProps: {
        heading: f.name,
        src: f.path
      },
      cssClass: 'wide-modal'
    });

    await modal.present();
  }

  openFeature(feature: Feature) {
    if (feature.presentation === 'iframe') {
      this.openIframeModal(feature);
    } else if (feature.presentation === 'external') {
      window.open(feature.path, '_blank');
    } else {
      this.navService.navigateForward(feature.path);
    }
    this.clickEvent.emit();
  }


  // async filterOutNavLinksByRoles() {
  //   //this.features = this.loginService.filterFeaturesByRole(this.features, this.authUser );
  // this.healthRecordFeatures = this.loginService.filterFeaturesByRole(this.healthRecordFeatures, this.authUser );
  // // this.messageFeatures = this.loginService.filterFeaturesByRole(this.messageFeatures, this.authUser );
  // // this.resourceFeatures = this.loginService.filterFeaturesByRole(this.resourceFeatures, this.authUser );
  //  console.log('healthRecordFeatures', this.healthRecordFeatures);
  // }
}
